<template>
  <div class="container h-100 bg-first">
    <b-overlay
      :show="show"
      variant="transparent"
      opacity="0.99"
      blur="5px"
      rounded="sm"
    >
      <template #overlay>
        <div class="text-center">
          <b-icon-controller
            font-scale="3"
            animation="cylon"
          />
          <p id="cancel-label">
            กรุณารอสักครู่...
          </p>
        </div>
      </template>
      <b-row>
        <b-col md="3" />
        <b-col md="6">
          <b-card
            class="mt-2"
          >
            <b-tabs
              justified
              pills
            >
              <b-tab
                v-if="agent.status_dip === '1'"
                title="ฝากเงิน"
                active
              >
                <b-card-text>

                  <div class="card p-1 mt-1">
                    <div class="text-center header-dark2">
                      <h5 class="font-weight-bolder m-0">
                        โอนแบบธนาคารไทย
                      </h5>
                    </div>
                    <div class="text-center">
                      <small class="text-danger"><u>"กรุณาใช้บัญชีที่ท่านสมัครโอนมาเท่านั้น"</u></small><br>
                      <small class="text-warning">*ระบบจะเติมเครดิตเกมอัตโนมัติ ภายในเวลา 1-2 นาที</small>
                    </div>

                    <div
                      style="margin: auto"
                      class="
          text-white text-center
          justify-content-center
          box-deposit
          p-1
          border-10
          mt-1
        "
                    >
                      <span> เติมเงินขั้นต่ำ 1 บาท </span>
                    </div>

                    <div class="card-bank mt-1 p-1">
                      <div class="d-flex align-items-center">
                        <img
                          src="/bankIcon/scb.png"
                          alt="bank"
                          height="35"
                          class="mr-50"
                        >
                        <div>
                          <h5
                            class="m-0 font-weight-bolder"
                            style="color: #000"
                          >
                            ธนาคารไทยพาณิชย์ จำกัด (มหาชน)
                          </h5>
                        </div>
                      </div>

                      <div class="mt-1 mb-1 d-flex justify-content-between">
                        <img
                          src="/chip.png"
                          alt="chip"
                          height="40"
                        >

                        <button
                          v-clipboard:copy="message"
                          class="btn btn-copy"
                          @click="copy"
                        >
                          <i class="fas fa-copy" /> คัดลอกบัญชี
                        </button>
                      </div>

                      <div>
                        <div class="box-bank">
                          <h4 class="m-0 dark font-weight-bolder">
                            {{ agent.dip_bank_accno }}
                          </h4>
                        </div>
                        <div>
                          <h5 class="m-0 dark">
                            {{ agent.dip_bank_accname }}
                          </h5>
                        </div>
                      </div>
                    </div>
                  </div>

                </b-card-text>
              </b-tab>
              <b-tab
                v-if="agent.dip_bank2_status === 1"
                title="ฝากเงิน"
                active
              >
                <b-card-text>

                  <div class="card p-1 mt-1">
                    <div class="text-center header-dark2">
                      <h5 class="font-weight-bolder m-0">
                        โอนแบบธนาคารไทย
                      </h5>
                    </div>
                    <div class="text-center">
                      <small class="text-danger"><u>"กรุณาใช้บัญชีที่ท่านสมัครโอนมาเท่านั้น"</u></small><br>
                      <small class="text-warning">*ระบบจะเติมเครดิตเกมอัตโนมัติ ภายในเวลา 1-2 นาที</small>
                    </div>

                    <div
                      style="margin: auto"
                      class="
          text-white text-center
          justify-content-center
          box-deposit
          p-1
          border-10
          mt-1
        "
                    >
                      <span> เติมเงินขั้นต่ำ 1 บาท </span>
                    </div>

                    <div class="card-bank mt-1 p-1">
                      <div class="d-flex align-items-center">
                        <div
                          style="
              background-color: #01a950;
              border-radius: 6px;
              width: 35px;
              height: 35px;
              padding: 5px;
              margin-right: 10px;
            "
                        >
                          <img
                            src="/bankIcon/kbank.svg"
                            alt="bank"
                            height="25"
                          >
                        </div>
                        <div>
                          <h5
                            class="m-0 font-weight-bolder"
                            style="color: #000"
                          >
                            ธนาคารกสิกรไทย
                          </h5>
                        </div>
                      </div>

                      <div class="mt-1 mb-1 d-flex justify-content-between">
                        <img
                          src="/chip.png"
                          alt="chip"
                          height="40"
                        >

                        <button
                          v-clipboard:copy="message2"
                          class="btn btn-copy"
                          @click="copy"
                        >
                          <i class="fas fa-copy" /> คัดลอกบัญชี
                        </button>
                      </div>

                      <div>
                        <div class="box-bank">
                          <h4 class="m-0 dark font-weight-bolder">
                            {{ agent.dip_bank2_accno }}
                          </h4>
                        </div>
                        <div>
                          <h5 class="m-0 dark">
                            {{ agent.dip_bank2_accname }}
                          </h5>
                        </div>
                      </div>
                    </div>
                  </div>

                </b-card-text>
              </b-tab>
              <b-tab
                v-if="agent.status_wit === '1'"
                title="ถอนเงิน"
              >
                <b-card-text>

                  <div class="card p-1 mt-1">
                    <div class="text-center header-dark2">
                      <h5 class="font-weight-bolder m-0">
                        ระบบการถอนเงินอัติโนมัติ
                      </h5>
                    </div>
                    <div class="text-center">
                      <small class="text-danger"><u>"กรุณาตรวจสอบบัญชีของท่านอีกครั้ง"</u></small><br>
                      <small class="text-warning">*ระบบจะทำกาถอนเงินเข้าบัญชีของท่าน ภายในเวลา 1-2 นาที</small>
                    </div>

                    <div
                      style="margin: auto"
                      class="
          text-white text-center
          justify-content-center
          box-deposit
          p-1
          border-10
          mt-1
        "
                    >
                      <span> ถอนเงินขั้นต่ำ 100 บาท </span>
                    </div>

                    <div class="card-bank mt-1 p-1">
                      <div class="d-flex align-items-center">
                        <div
                          :style="`
              background-color: ${userdata.bg};
              border-radius: 6px;
              width: 35px;
              height: 35px;
              padding: 5px;
              margin-right: 10px;`
                          "
                        >
                          <img
                            :src="`/bankIcon/${userdata.path_photo}`"
                            alt="bank"
                            height="25"
                          >
                        </div>
                        <div>
                          <h5
                            class="m-0 font-weight-bolder"
                            style="color: #000"
                          >
                            {{ userdata.bankname }}
                          </h5>
                        </div>
                      </div>

                      <div class="mt-1 mb-1 d-flex justify-content-between">
                        <img
                          src="/chip.png"
                          alt="chip"
                          height="40"
                        >

                        <!-- <button
                        class="btn btn-copy"
                        @click="copy"
                      >
                        <i class="fas fa-copy" /> คัดลอกบัญชี
                      </button> -->
                      </div>

                      <div>
                        <div class="box-bank">
                          <h4 class="m-0 dark font-weight-bolder">
                            {{ userdata.acc_no }}
                          </h4>
                        </div>
                        <div>
                          <h5 class="m-0 dark">
                            {{ userdata.name }} {{ userdata.surname }}
                          </h5>
                        </div>
                      </div>
                    </div>

                    <div class="mt-1">
                      <label for="test">ระบุจำนวนเงินที่ต้องการถอน</label>
                      <input
                        id="balance"
                        v-model="amount"
                        type="number"
                        name="balance"
                        placeholder="จำนวนเงินที่ต้องการถอน"
                        class="form-control"
                      >
                      <b-button
                        variant="gradient-primary"
                        class="mt-1 btn-theme-two"
                        block
                        @click="submit()"
                      >
                        ถอนเงิน
                      </b-button>
                    </div>
                  </div>

                </b-card-text>
              </b-tab>
            </b-tabs>
          </b-card>
        </b-col>
        <b-col md="3" />
      </b-row>
    </b-overlay>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BTabs, BTab, BCardText, BButton,
  BOverlay,
  BIconController,
} from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BTabs,
    BTab,
    BCardText,
    BButton,
    BOverlay,
    BIconController,
  },
  data() {
    return {
      amount: 0,
      message: '123456',
      message2: '123456',
      agent: null,
      userdata: null,
      UserData: JSON.parse(localStorage.getItem('userData')),
      show: false,
    }
  },
  mounted() {
    this.GetAgent()
    this.GetUser()
  },
  methods: {
    GetUser() {
      this.$http
        .get('https://api.sabaideelotto.com/api/lottery/user/show')
        .then(ress => {
          console.log(ress.data)
          this.userdata = ress.data
        })
    },
    GetAgent() {
      this.$http
        .post('https://api.sabaideelotto.com/api/lottery/agent/show')
        .then(ress => {
          // console.log(ress.data)
          this.agent = ress.data
          this.message = ress.data.dip_bank_accno
          this.message2 = ress.data.dip_bank2_accno
        })
    },
    submit() {
      // console.log(this.amount)
      this.show = true
      const params = {
        username: this.userdata.username,
        amount: this.amount,
        uid: this.userdata.id,
        wto: 'acc',
      }
      // console.log(params)
      this.$http
        .post('https://api.sabaideelotto.com/api/lottery/withdraw/store', params)
        // eslint-disable-next-line no-unused-vars
        .then(response => {
          this.show = false
          this.amount = ''
          this.Success('ถอนเงินสำเร็จ')
        })
        .catch(error => {
          this.show = false
          this.loading = false
          this.SwalError(error.response.data.message)
        })
    },
    copy() {
      this.$swal({
        icon: 'success',
        title: '<h3 style="color: #FFF">คัดลอกเลขบัญชีสำเร็จ</h3>',
        showConfirmButton: false,
        timer: 1500,
        background: '#202124',
      })
    },
    Success(mes) {
      this.$swal({
        icon: 'success',
        title: '<h3 style="color:#000">ทำรายการสำเร็จ<h3>',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-success',
        },
      })
    },
    SwalError(mes) {
      this.$swal({
        icon: 'error',
        title: '<h3 style="color:#000">ขออภัยค่ะ!<h3>',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-success',
        },
      })
    },
  },

}
</script>

<style scoped>
.bg-tabs {
  background-color: #8c0000;
  border-radius: 5px;
  padding: 10px;
}

.tab-1 {
  border-bottom: 1px solid #e3e3e3;
  background-color: #8c0000;
  padding: 10px;
}

.tab-2 {
  border-bottom: 1px solid #e3e3e3;
  background-color: #8c0000;
  padding: 10px;
}

.btn-copy {
  background: rgb(248, 6, 115);
  background: linear-gradient(90deg, rgba(248, 6, 115, 1) 0%, rgba(255, 125, 235, 1) 47%, rgba(136, 54, 229, 1) 100%);
  color: #fff;
  border: none;
  border-radius: 6px;
  height: 35px;
  padding: 2px 10px;
}

.card-bank {
  min-height: 175px;
  border-radius: 14px;
  /* background-image: url('/bg-bank2.svg'); */
  background: linear-gradient(0deg,
      rgba(185, 153, 91, 0.9),
      rgba(221, 204, 151, 0.9),
      rgba(185, 153, 91, 0.9)),
    url("/bg-bank2.svg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  border-radius: 6px;
  padding: 10px;
}

.dark {
  color: #000 !important;
}

.header-dark {
  background: linear-gradient(180deg, #626166, #3d3a3d) !important;
  padding: 10px;
}

.card-list {
  margin: 0 15px;
  background: #2e2e2e;
  box-shadow: 0 4px 10px rgb(0 0 0 / 12%);
  border-radius: 10px;
  padding: 10px;
}

.box-deposit {
  background: hsla(0, 0%, 74.1%, .3);
  width: 60%;
  border-radius: 8px;
  color: #000 !important;
  font-weight: 600;
}
</style>
